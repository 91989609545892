<template>
  <router-view />
</template>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
  background-color: #000;
  color: #fff;
}

body {
  font-family: 'Orbitron', sans-serif;
  font-family: 'Sorts Mill Goudy', serif;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-family: 'Junge', serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  color: #2c3e50;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  line-height: 1.1;
}
</style>