
import { defineComponent } from 'vue'
import axios from 'axios'
import { APP_URL } from '../../constants'
import { router } from '../main'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      password: '',
      loading: false,
      hasSetInvalidPassword: false,
      shake: false
    }
  },
  methods: {
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${APP_URL}/login`,
          {
            password
          },
          { withCredentials: true }
        )
        .then(function(res) {
          router.go(0)
        })
        .catch(function() {
          if (this_.hasSetInvalidPassword) {
            this_.shake = false
            this_.$nextTick(() => {
              this_.shake = true
            })
          }
          this_.hasSetInvalidPassword = true
        })
    }
  }
})
