<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { APP_URL } from '../../constants'
import { router } from '../main'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      password: '',
      loading: false,
      hasSetInvalidPassword: false,
      shake: false
    }
  },
  methods: {
    async handleSubmit() {
      const { password } = this

      // Stop here if form is invalid
      if (!password) {
        return
      }
      const this_ = this
      this.loading = true
      await axios
        .post(
          `${APP_URL}/login`,
          {
            password
          },
          { withCredentials: true }
        )
        .then(function(res) {
          router.go(0)
        })
        .catch(function() {
          if (this_.hasSetInvalidPassword) {
            this_.shake = false
            this_.$nextTick(() => {
              this_.shake = true
            })
          }
          this_.hasSetInvalidPassword = true
        })
    }
  }
})
</script>

<template>
  <form class="loginForm" @submit.prevent="handleSubmit">
    <label class="passwordLabel" htmlFor="password">Password</label>
    <input
      v-model="password"
      type="password"
      name="password"
      class="passwordInput"
      autocomplete="off"
    />
    <div v-if="hasSetInvalidPassword">
      <div :class="['invalidPassword', { shake: shake }]">Invalid password</div>
    </div>
  </form>
</template>

<style scoped lang="scss">
input[type="password"] {
  -moz-appearance: none; /* Remove Firefox-specific styling */
}

.loginForm {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-family: 'Orbitron', sans-serif;

  .passwordLabel {
    display: flex;
    width: 100%;
    text-align: center;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    font-size: 3rem;
  }

  .passwordInput {
    font-family: Arial, sans-serif;
    width: 100%;
    margin-top: 0.5rem;
    font-size: 4rem;
    letter-spacing: 1rem;
    padding: 2rem 1rem;
    background: #01af81;
    color: #000;
    border: 2px solid #01af81;
    outline: none;
    height: 4rem;
    line-height: 4rem;
    caret-color: transparent;
  }

  .passwordInput:focus {
    border: 2px solid #5fffe0;
  }

  .invalidPassword {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }
}

@-moz-document url-prefix() {
  .passwordInput {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 1350px) {
  .loginForm {
    width: 70%;
  }
}

@media (max-width: 750px) {
  .loginForm {
    width: 80%;

    .passwordLabel {
      font-size: 2rem;
    }

    .passwordInput {
      height: 1.5rem;
      padding: 1.5rem 0.5rem;
      font-size: 3rem;
      letter-spacing: 0.5rem;
    }

    @-moz-document url-prefix() {
      .passwordInput {
        font-size: 1.5rem !important;
      }
    }

    .invalidPassword {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
}

@media (max-width: 400px) {
  .loginForm {
    width: 90%;

    .passwordLabel {
      font-size: 1.25rem;
      letter-spacing: 0;
    }

    .passwordInput {
      height: 1rem;
      padding: 1rem 0.5rem;
      font-size: 2rem;
      letter-spacing: 0;
    }

    @-moz-document url-prefix() {
      .passwordInput {
        font-size: 1.25rem !important;
      }
    }

    .invalidPassword {
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}
</style>
